<template>
    <div>
        <ScrollPanel :style="edit? 'width: 100%; height: 600px': 'width: 100%; height: 600px'">
            <div class="p-grid">
                <DataTable :value="students" :filters="filters" scrollable :paginator="true" dataKey="id"
                           paginatorPosition="bottom" :rows="page.per_page" :lazy="true" :totalRecords="page.total"
                           :loading="loading"  @page="onPage($event)" class="content">
                    <template #header v-if="enroll">
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left v-if="enroll.is_holder_student === 'y'">
                                <div class="p-col-12">
                                    <p><strong>Titular: </strong> {{ enroll?.contract?.holder?.name}} {{ enroll?.contract?.holder?.last_name}}</p>
                                    <p> <strong>Cédula: </strong>{{ enroll?.contract?.holder?.dni }}</p>
                                    <p> <strong>Email: </strong>{{ enroll?.contract?.holder?.email }}</p>
                                </div>


                            </template>
                        </Toolbar>
                    </template>

                    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :rowClass="col.class"></Column>

                </DataTable>
            </div>
        </ScrollPanel>

    </div>

</template>

<script>
    import service from '../../service/student.service'
    import {mapState} from "vuex";

    export default {
        name: "StudentList",
        components: {
        },
        props: {
            enroll: Object,
            edit: Boolean
        },
        data() {
            return {
                columns: [
                    {field: 'dni', header: 'DNI'},
                    {field: 'name', header: 'Nombre'},
                    {field: 'last_name', header: 'Apellido'},
                    {field: 'email', header: 'Correo', class:'content'},
                    {field: 'phone', header: 'Teléfono'},
                ],
                page: {
                    page: 1,
                    per_page: 5,
                    total: 0,
                    order: "desc"
                },
                students: [],
                data: {},
                filters: {},
                submitted: false,
                loading: false,
                verifyDataDialog: false
            }
        },
        methods: {

            onPage(event) {
                this.page.page = event.page + 1;
                this.getData()
            },

            getData() {
                this.loading = true;
                if(this.enroll) {
                    service.getByEnrollment(this.enroll.id, this.page).then(x => {
                        this.students = x.data;
                        this.page.total = this.students.length;
                    }).finally(() =>  this.loading = false)
                } else {
                    service.get(null, this.page).then(x => {
                        this.students = x.data.data;
                        this.page.total = x.data.total;

                    }).finally(() =>  this.loading = false)
                }

            },

        },
        computed: {
            ...mapState('enroll', ['country']),
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped lang="scss">

    .p-fieldset {
        margin-top: 15px;
    }
    .content {
        overflow-wrap: break-word;
    }

</style>
